<script setup lang="ts">
const props = defineProps({
  picture: {
    type: String,
    default: null,
  },
  name: {
    type: String,
    default: null,
  },
  isBasic: {
    type: Boolean,
    default: true,
  },
  size: {
    type: String,
    default: 'large',
  },
  isLoading: {
    type: Boolean,
    default: false,
  },
})

function getInitials(name: string) {
  const [firstName, lastName] = name.split(' ')
  const firstLetter = firstName ? firstName.substring(0, 1) : ''
  const lastLetter = lastName ? lastName.substring(0, 1) : ''
  return `${firstLetter}${lastLetter}`
}

const getSize = computed(() => {
  if (props.size === 'large')
    return { photo: 'h-[12rem] w-[12rem] md:h-[14rem] md:w-[14rem]', text: 'text-6xl md:text-7xl' }

  if (props.size === 'medium')
    return { photo: 'h-[7rem] w-[7rem] md:h-[11rem] md:w-[11rem]', text: 'md:text-4xl' }

  if (props.size === 'small')
    return { photo: 'h-[3rem] w-[3rem]', text: 'text-2xl' }
})
</script>

<template>
  <div
    class=" group flex relative justify-center items-center rounded-full bg-slate-300"
    :class="[{ 'hover:bg-slate-400 cursor-pointer': !isBasic }, getSize?.photo]"
  >
    <pv-progress-spinner class="w-full! h-full! p-[10%]" v-if="isLoading" />
    <img
      v-else-if="picture"
      class="w-full h-full rounded-full"
      :src="picture"
    >
    <span
      v-else
      class="text-black uppercase"
      :class="getSize?.text"
    >{{ getInitials(name) }}</span>
    <i
      v-if="!isBasic"
      class="pi pi-camera group-hover:text-yellow-500 text-gray-400 absolute bottom-0 right-9 p-2 bg-white rounded-full"
      style="font-size: 2rem"
    />
  </div>
</template>
